<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-end py-0">
        <!-- Botón para nueva -->
        <v-btn
          v-if="permiso == 1"
          color="success"
          small fab
          @click="nuevaGarantia()"
          title="Nueva Garantía"

        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row class="mt-0">
          <v-col cols="12" class="pt-0" >
            <!-- Listado de garantias -->
            <v-data-table
              class="cebra mt-2 pb-0"
              :headers="headers"
              :items="garantias"
              :loading="load"
              :search="search"
              disable-pagination
              hide-default-footer
              dense
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-start pb-2" >
                  <v-col cols="12" sm="6" md="3">
                    <SearchDataTableVue
                      v-model="search"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="9" class="d-flex justify-end align-center">
                    <DownloadDataTable
                      name="Contactas garantías"
                      :data="garantias"
                      :headers="headers_excel"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }" >
                <div class="d-flex justify-around">
                  <!-- Info -->
                  <v-tooltip left color="info">
                    <template v-slot:activator="{ on }">
                      <v-icon class="" color="info" v-on="on" small>fas fa-info-circle</v-icon>
                    </template>
                    <div :style="`width: 400px;`">
                    <v-row >
                      <v-col cols="12" class="font-weight-bold word-break-normal w-100">
                        {{ item.observacion ? item.observacion : 'No tiene observaciones' }}
                      </v-col>
                    </v-row>
                    </div>
                  </v-tooltip>
                  <!-- Ver / Editar -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon :color="$vuetify.theme.dark ? 'accent' : 'primary'" @click="controlarVerEditar(item)" v-on="on">
                        <v-icon class="" small>fas fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                  <!-- Habilitar / Deshabilitar -->
                  <v-tooltip bottom v-if="permiso == 1">
                    <template v-slot:activator="{ on }">
                      <div v-on="on" >
                        <BtnConfirmar
                        :color="item.inhabilitada == 1 ? 'success' : 'error'"
                        icon_button
                        small clase="pt-1"
                        :icono="item.inhabilitada == 1 ? 'fas fa-check' : 'fas fa-ban'"
                        :texto="`¿Desea ${item.inhabilitada == 1 ? 'habilitar' : 'inhabilitar'} la garantía actual?`"
                        @action="inhabilitarHabilitar(item)"/>
                      </div>
                    </template>
                    <span>{{ item.inhabilitada == 1 ? 'Habilitar' : 'Inhabilitar' }}</span>
                  </v-tooltip>
                </div>
              </template>
              <!-- No hay datos -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ModalNvoEditarContactoGarantia
      v-model="objModal.activo"
      :datos="objModal"
      @actualizar="initForm"
    />
  </div>
</template>

<script>
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import { order_list_by } from '../../util/utils.js'
import moment from 'moment'
import ModalNvoEditarContactoGarantia from '../../components/ventas/ModalNvoEditarContactoGarantia.vue';
import BtnConfirmar from '../../components/util/BtnConfirmar.vue';
import DownloadDataTable from '../../components/util/DownloadDataTable.vue';

export default {
  name: 'ContactosGarantias',
  data() {
    return {
      moment: moment,
      panel: 0,
      load: false,
      search: '',
      permiso: 0,
      filtro:{
        garantia_id:       0,
        marcas:            null,
        plazo:             null,
        contacto:          null,
        direccion:         null,
        horario_atencion:  null,
        observacion:       null
      },
      garantias: [],
      headers: [
        { text: 'Marcas', value: 'marcas' },
        { text: 'Plazo', value: 'plazo' },
        { text: 'Contacto', value: 'contacto' },
        { text: 'Horarios', value: 'horario_atencion' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      pantallaChica: this.$vuetify.breakpoint.xs,
      objModal:{
        activo: false,
        nuevo: false,
        editar: false,
        garantia:{},
        permiso: 0,
      },
      headers_excel:{
        'Marcas':    'marcas',
        'Plazo':     'plazo',
        'Contacto':  'contacto',
        'Horarios':  'horario_atencion',
        'Dirección': 'direccion',
      }
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true;
      const res                 = await this.$store.dispatch('ventas/getGarantias');
      this.$store.state.loading = false;
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.garantias = res.garantias;
      order_list_by(this.garantias, 'marcas');
      this.permiso   = res.permiso;
    },
    async buscar(){
      
    },
    limpiarFiltros(){
      this.filtro = {
        garantia_id:       0,
        marcas:            null,
        plazo:             null,
        contacto:          null,
        direccion:         null,
        horario_atencion:  null,
        observacion:       null
      }
    },
    controlarVerEditar(item){
      this.objModal.permiso  = this.permiso;
      this.objModal.garantia = item;
      this.objModal.nuevo    = false;
      this.objModal.activo   = true;
    },
    async nuevaGarantia(){
      this.objModal.permiso = 1;
      this.objModal.nuevo  = true;
      this.objModal.garantia = {
        garantia_id:       0,
        marcas:            null,
        plazo:             null,
        contacto:          null,
        direccion:         null,
        horario_atencion:  null,
        observacion:       null
      }
      this.objModal.activo = true;
    },
    async inhabilitarHabilitar(item){
      let accion = null;
      if(item.garantia_id != null || item.garantia_id != undefined){
        if(item.inhabilitada == 1){
          accion = 1;
        }else{
          accion = 0;
        }
        this.$store.state.loading = true;
        const res                 = await this.$store.dispatch("ventas/inhabilitarHabilitarGarantia", { id: item.garantia_id, accion: accion });
        this.$store.state.loading =  false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
        }
        let pos = this.garantias.indexOf(item)
        if(pos != -1){
          this.garantias[pos].inhabilitada = accion == 1 ? null : 1;
          this.$store.dispatch('show_snackbar', { text: `Garantía ${item.garantia_id} ${accion == 1 ? 'habilitada' : 'inhabilitada'} exitosamente.`, color: 'success' })
        }
      }
    },
  },
  components: {
    BtnFiltroVue,
    SearchDataTableVue,
    ModalNvoEditarContactoGarantia,
    BtnConfirmar,
    DownloadDataTable
  },
  watch: {
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>