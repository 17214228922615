<template>
<div>
  <v-dialog 
    v-model="activo"  
    width="600"
  >
    <v-card>
      <v-card-title>
        <div style="font-size: 20px">{{ nuevo ? 'Nuevo Contacto de Garantía' : (editar ? 'Editar Contacto Garantía' : (pantallaChica ? 'Detalle Contacto Garantia' : 'Detalles de Contacto de Garantía')) }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text  class="my-3 pb-0">
        <v-form @submit.prevent="guardar()">
          <v-row>
            <v-col cols="12" class="d-flex justify-end pt-2 pb-0" v-if="!nuevo && permiso == 1">
              <v-switch
                v-model="editar"
                label="Editar"
                class="d-flex align-center py-0 my-0"
                hide-details
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pb-1" :class="nuevo? ' pt-2' : 'pt-0'">
              Marcas
              <v-text-field
                v-model.trim="garantiaCopia.marcas"
                hide-details
                outlined
                dense
                :error="marcaError"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-1">
              Plazo
              <v-text-field
                v-model.trim="garantiaCopia.plazo"
                hide-details
                outlined
                dense
                :error="plazoError"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-1">
              Contacto
              <v-text-field
                v-model.trim="garantiaCopia.contacto"
                hide-details
                outlined
                dense
                :error="contactoError"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-1">
              Direccion
              <v-text-field
                v-model.trim="garantiaCopia.direccion"
                hide-details
                outlined
                dense
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="py-1">
              Horarios de Atención
              <v-text-field
                v-model.trim="garantiaCopia.horario_atencion"
                hide-details
                outlined
                dense
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-1">
              Observación
              <v-textarea
                v-model.trim="garantiaCopia.observacion"
                hide-details
                outlined
                dense
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex" :class="pantallaChica ? 'justify-center' : 'justify-end'">
        <v-btn 
          class="mr-2"
          @click="activo = false"
          :color="this.$vuetify.theme.dark ? 'warning' : 'secondary'">
          {{ !editar ? 'Volver' : 'Cancelar' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          class=""
          :texto="(nuevo ? 'Está seguro de grabar la garantía?' : 'Está seguro de guardar los cambios?' )+' Confirmar acción.'"
          :nombre="nuevo ? 'Grabar' : 'Guardar cambios'"
          :load="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue';

export default {
  name: 'ModalNvoEditarGarantia',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }
  },
  computed:{
    activo: {
      get(){
        return this.datos.activo;
      },
      set(value){
        this.$emit('input', value);
      }
    },
    nuevo:{
      get(){
        return this.datos.nuevo;
      }
    },
    permiso:{
      get(){
        return this.datos.permiso;
      }
    }
  },
  data(){
    return {
      load: true,
      editar: false,
      garantiaCopia: {},
      marcaError: false,
      plazoError: false,
      contactoError: false,
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  methods:{
    validarCampos(){
        let error = {}
        if( !this.garantiaCopia.marcas ){
          error.text      = 'Debe completar el campo Marcas'
          error.color     = 'warning';
          this.marcaError = true;
          return error
        }
        if( !this.garantiaCopia.plazo ){
          error.text      = 'Debe completar el campo plazo'
          error.color     = 'warning'
          this.plazoError = true;
          return error
        }
        if( !this.garantiaCopia.contacto ){
          error.text         = 'Debe completar el campo contacto.'
          error.color        = 'warning'
          this.contactoError = true;
          return error
        }
        return error;
      },
    async guardar(){
      let error = this.validarCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }
      if(JSON.stringify(this.garantiaCopia) !=  JSON.stringify(this.datos.garantia)){
        this.load                 = true;
        this.$store.state.loading = true;
        const res                 = await this.$store.dispatch('ventas/guardarGarantia', this.garantiaCopia);
        this.$store.state.loading = false;
        this.load                 = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', {text: res.message , color: 'error'})
        }
        let cad = this.garantiaCopia.garantia_id == 0 ? 'grabada' : (this.garantiaCopia.garantia_id+' actualizada')
        this.$store.dispatch('show_snackbar', { text: `Garantía ${cad} correctamente.`, color: 'success' })
        
        this.activo = false;
        this.$emit('actualizar');
      }else {
        if(!this.nuevo){
          this.activo = false;
          return this.$store.dispatch('show_snackbar', {text: 'La garantía no fue modificada. No se realizaron cambios.' , color: 'info'})
        }
      }
    },
  },
  components:{
    BtnConfirmar
  },
  watch:{
    activo: function(val){
      if(val){
        if(this.datos.nuevo){
          this.editar = true;
        }
        this.garantiaCopia = JSON.parse(JSON.stringify(this.datos.garantia))
      }else{
        this.editar = false;
      }
    },
    editar: function(val){
      if(!val){
        this.garantiaCopia = JSON.parse(JSON.stringify(this.datos.garantia))
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
    'garantiaCopia.marcas':{
      handler: function(val){
        if(val){
          this.marcaError = false;
        }
      },
      deep: true,
    },
    'garantiaCopia.plazo':{
      handler: function(val){
        if(val){
          this.plazoError = false;
        }
      },
      deep: true,
    },
    'garantiaCopia.contacto':{
      handler: function(val){
        if(val){
          this.contactoError = false;
        }
      },
      deep: true,
    }
  }
}
</script>
